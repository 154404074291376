<template>
  <div class="order">
    <div>
      <p
        v-if="orderInfo.orderType == 2 || orderInfo.orderType == 3 "
        class="title"
      >
        <span v-if="$store.state.language == 1">
          憑取餐號碼<span>{{ orderInfo.shopBillNo }}</span
          >到櫃台取餐
        </span>

        <span v-else
          >Pick Up Your Meal With Pickup Code 
          <span>{{ orderInfo.shopBillNo }}</span></span
        >
      </p>
      <p class="title" v-else>
        <span v-if="$store.state.language == 1">
          預計 <span>{{orderInfo.deliveryTime}}</span> 送到
        </span>
        <span v-else>
          Estimated Delivery Time at <span>{{orderInfo.deliveryTime}}</span>
        </span>
      </p>
    </div>
    <div class="orderTitle">
      <p>
        <i></i>
        <span>{{ orderInfo.shopName }}</span>
      </p>
      <div class="typeIcon">
        <p
          class="orderType homeActive"
          v-if="orderInfo.orderType==1"
        >
          <i></i>
          {{ $store.state.language == 1 ? "外送速遞" : "Delivery" }}
        </p>
         <p
          class="orderType pickActive"
          v-if="orderInfo.orderType==2"
        >
          <i></i>
          {{ $store.state.language == 1 ? "自取" : "Self pick-up" }}
        </p>
        <p
          class="orderType shopActive"
          v-if="orderInfo.orderType==3"
        >
          <i></i>
          {{ $store.state.language == 1 ? "堂食" : "Dine-in" }}
        </p>
        <span
          class="sendType"
          :class="{
            one: orderInfo.orderStatusBg == 1,
            two: orderInfo.orderStatusBg == 2,
            three: orderInfo.orderStatusBg == 3,
            four: orderInfo.orderStatusBg == 4,
          }"
          >{{ orderInfo.orderStatus }}</span
        >
      </div>
    </div>
    <div class="orderInfo" @click="lookOrderDetail">
      <div class="orderInfoLeft">
        <img src="@/assets/icon/orderIcon.png" alt="" />
        <div>
          <p>
            {{ orderInfo.name[0] }}
          </p>
        </div>
      </div>
      <div class="orderInfoRight">
        <p>
          <span>$</span>
          {{ parseInt(orderInfo.amount) / 100 }}
        </p>
        <p></p>
      </div>
    </div>
    <p
      class="btn"
      v-if="
        orderInfo.orderStatusCode == 'S006' ||
        orderInfo.orderStatusCode == 'S007' ||
        orderInfo.orderStatusCode == 'S010'
      "
      @click="showConfirm"
    >
      <!-- <p class="btn" @click="showConfirm"> -->
      <span>{{ $store.state.language == 1 ? "再次落單" : "Reorder" }}</span>
    </p>
    <confirm-box-new
      v-show="show"
      :title="title"
      :cancelBtn="cancelBtn"
      :finishBtn="finishBtn"
      @closeBox="showConfirm"
      @finish="finish"
    ></confirm-box-new>
  </div>
</template>
<script>
import ConfirmBoxNew from "../../components/common/CnfirmBoxNew.vue";
import { postDC } from "../../assets/utils/request";
export default {
  name: "OrderItem",
  data() {
    return {
      show: false,
      sendType: "配餐中",
      title: this.$store.state.language==1? "是否要再次落單?":"Confirm Reorder?",
      cancelBtn: this.$store.state.language==1?"返回":"Cancel",
      finishBtn: this.$store.state.language==1?"確認落單":"Confirm",
      errorAlert:this.$store.state.language==1?"網絡異常，請重試":"Connection error has occurred, please retry",
      sendWay: 1,
    };
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    showConfirm() {
      this.show = !this.show;
    },
    finish() {
      this.changePage();
    },
    async changePage() {
      var params = {
        actionName: "candao.orderOwn.getOrderDetail",
        content: {
          orderId: this.orderInfo.reference,
        },
      };
      let result = await postDC("Action", params);
      if (result.status != 1) return this.$toast(this.errorAlert);
      let menuParam = {
        actionName: "candao.product.getProductMenu",
        content: {
          storeId: result.data.storeId,
          sendType: result.data.sendType,
        },
      };
      let menuResult = await postDC("Action", menuParam);
      if (menuResult.status != 1) return this.$toast(this.errorAlert);
      var againParams = {
        actionName: "candao.orderOwn.reApplyOrder",
        content: {
          orderId: result.data.orderId,
          menuId: menuResult.data.menuId,
        },
      };

      let againResult = await postDC("Action", againParams);
      if (againResult.status == 1) {
        if (againResult.data.productsOwn) {
          var productsOwn = againResult.data.productsOwn;
          var product = [];
          for (var i = 0; i < productsOwn.length; i++) {
            var obj = {};
            for (var key in productsOwn[i]) {
              if (typeof productsOwn[i][key] != "object") {
                obj[key] = productsOwn[i][key];
              }
            }
            obj.selectedFood = productsOwn[i];
            product.push(obj);
          }
        }
        if (result.data.sendType == 4) {
          result.data.sendType = 1;
        }
        var foodParam = {
          storeId: result.data.storeId,
          sendType: result.data.sendType,
          products: product || [],
        };
        if (againResult.data.errorProducts) {
          foodParam.errorFood = againResult.data.msg;
        }
        foodParam = JSON.stringify(foodParam);
        foodParam = encodeURIComponent(foodParam);
        this.$router.push("/orderFood/" + foodParam);
      }
    },
    lookOrderDetail() {
      this.$router.push("/orderDetail/" + this.orderInfo.recordId);
    },
  },
  components: {
    ConfirmBoxNew,
  },
};
</script>
<style scoped>
.order {
  width: 100%;
  border-radius: 0.08rem;
  overflow: hidden;
  background-color: #fff;
  padding-bottom: 0.16rem;
  margin-bottom: 0.16rem;
}
.title {
  width: 100%;
  height: 0.4rem;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.4rem;
  color: #03060d;
  text-align: center;
  background-color: #F5E9EB;
  font-weight: 500;
}
.title span span {
  color: #e4022b;
}
.orderTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.16rem;
}
.orderTitle p {
  height: 0.2rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.2rem;
  color: #03060d;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.orderTitle p i {
  width: 0.16rem;
  height: 0.2rem;
  background: url("../../assets/icon/dd.png") no-repeat center;
  background-size: cover;
  margin-right: 0.02rem;
}
.orderTitle div {
  display: flex;
  align-items: center;
}
.typeIcon p {
  display: flex;
  align-items: center;
}
.typeIcon p i {
  width: 0.12rem;
  height: 0.1rem;
  background: url("../../assets/icon/sd.png") no-repeat center;
  background-size: cover;
}
.orderType {
  height: 0.2rem;
  overflow: hidden;
  margin-left: 0.04rem;
  font-size: 0.1rem !important;
  color: #fff;
  text-align: center;
  line-height: 0.2rem;
  padding: 0 0.06rem;
  border-radius: 0.02rem;
  margin-right: 0.08rem;
}
.orderType.homeActive {
  background-color: #f2f3f5;
}
.orderType.shopActive {
  background-color: #f5e9eb;
}
.orderType.pickActive{
  background-color: #FF7C9B;
}
.orderType.pickActive i{
  width: 0.12rem;
  height: 0.12rem;
  background: url("../../assets/icon/ziqu.png");
  background-size: cover;
  margin-left: 0.04rem;
}
.orderType.homeActive i {
  background: url("../../assets/icon/sd.png") no-repeat center;
  background-size: cover;
  margin-left: 0.04rem;
}
.orderType.shopActive i {
  background: url("../../assets/icon/ts.png") no-repeat center;
  background-size: cover;
  margin-left: 0.04rem;
}

.sendType {
  padding: 0 0.06rem;
  height: 0.2rem;
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.2rem;
  color: #03060d;
  border-radius: 0.02rem;
}
.orderInfo {
  padding: 0 0.16rem;
}
.orderInfo,
.orderInfoLeft {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.orderInfoLeft {
  align-items: center;
}
.orderInfoLeft img {
  width: 0.56rem;
  height: 0.56rem;
  object-fit: cover;
}
.orderInfoLeft div {
  width: 1.5rem;
  height: .4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.18rem;
  color: #030f14;
  margin-left: 0.08rem;
}
.orderInfoLeft div p:first-child {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: left;
}
/* .orderInfoLeft div p:last-child {
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.16rem;
  color: #b1b1b3;
  text-align: left;
  margin-top: 0.04rem;
  height: 0.32rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
} */
.orderInfoRight {
  height: 0.2rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.2rem;
  color: #030f14;
}
.orderInfoRight p:first-child span {
  font-size: 0.1rem;
}
.orderInfoRight p:last-child {
  font-size: 0.14rem;
  color: #b1b1b3;
}
.line {
  display: block;
  margin: 0.17rem 0.16rem 0.11rem;
  border: 1px dashed #cacbcc;
}
.btn {
  padding: 0 0.16rem;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.17rem;
}
.btn span {
  width: 100%;
  height: 0.4rem;
  background: linear-gradient(90deg, #fe1112 0%, #e4022b 100%);
  border-radius: 0.08rem;
  color: #fff;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.4rem;
}
.sendType.one {
  background-color: rgba(229, 164, 60, 1);
}
.sendType.two {
  background-color: rgba(147, 178, 249, 1);
}
.sendType.three {
  background-color: rgba(176, 216, 76, 1);
}
.sendType.four {
  background-color: #e4022b;
  color: #fff;
}
</style>