<template>
  <div class="coupon">
    <topbar :left="false" :title="new_DDLB_woDeDingDan"></topbar>
    <div class="tabBar">
      <p @click="selectTab(1)" :class="{ active: type == 1 }">
        {{ lanType == 1 ? "全部" : "All" }}
        <span></span>
      </p>
      <p @click="selectTab(2)" :class="{ active: type == 2 }">
        {{ lanType == 1 ? "餐廳點餐" : "Restaurant Ordering" }}
        <span></span>
      </p>
      <p @click="selectTab(3)" :class="{ active: type == 3 }">
        {{ lanType == 1 ? "好味速遞" : "Online Ordering" }}
        <span></span>
      </p>
    </div>
    <mescroll-vue
      ref="mescroll"
      :down="mescrollDown"
      :up="mescrollUp"
      @init="mescrollInit"
    >
      <div class="list" v-if="orderList.length" @scroll="bodyScroll" id="oList">
        <order-item
          v-for="item in orderList"
          :key="item.transactionId"
          :orderInfo="item"
        ></order-item>
      </div>
      <div class="list" v-else>
        <none-data
          :title="lanType == 1 ? '暫時未有訂單記錄' : 'No orders yet'"
          :btnText="lanType == 1 ? '去點餐' : 'Order Now'"
          :type="1"
        ></none-data>
      </div>
    </mescroll-vue>
  </div>
</template>
<script>
import OrderItem from "../components/orderItem/OrderItem.vue";
import NoneData from "../components/common/NoneData.vue";
import Topbar from "../components/Topbar.vue";
import MescrollVue from "mescroll.js/mescroll.vue";
import language from "../assets/js/language";

import { postPaas } from "../assets/utils/request";

export default {
  name: "OrderList",
  data() {
    return {
      type: 1,
      couponNumActive: 1,
      showList: true,
      orderList: [], //订单列表
      list: [],
      mescroll: null,
      mescrollDown: {
        //下拉刷新
        callback: this.downCallback,
        textLoading: "",
        textOutOffset: "",
        textInOffset:
          this.$store.state.language == 1
            ? "往下滑更新版面"
            : "Swipe down to refresh",
      },
      mescrollUp: {
        //上啦加载
        // callback: this.upCallback,
      },
      showAgain: false,
      scrollTopHeight: 0,
      startOrder1: "",
      new_DDLB_woDeDingDan:
        this.$store.state.language == 1 ? "我的訂單" : "My Orders",
    };
  },
  methods: {
    downCallback() {
      this.getOrderList();
    },
    // upCallback(page, mescroll) {
    //   //上啦加载回调
    //   this.$nextTick(() => {
    //     mescroll.endSuccess();
    //   });
    // },
    mescrollInit(mescroll) {
      this.mescroll = mescroll; // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },
    selectTab(type) {
      this.orderList = [];
      this.type = type;
      if (type == 1) {
        this.orderList = this.list;
      } else if (type == 2) {
        for (var i = 0; i < this.list.length; i++) {
          if (this.list[i].orderType == 3) {
            this.orderList.push(this.list[i]);
          }
        }
      } else if (type == 3) {
        for (var a = 0; a < this.list.length; a++) {
          if (this.list[a].orderType != 3) {
            this.orderList.push(this.list[a]);
          }
        }
      }
    },
    secectCouponNumType(type) {
      this.couponNumActive = type;
    },
    // 获取订单列表
    async getOrderList() {
      var params = {
        actionName: "candao.member.orderList",
        content: {},
      };
      if (this.$store.state.language == 1) {
        params.content.language = "TC";
      }
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        var type = "chinese";
        if (this.lanType != 1) {
          type = "english";
        }
        var shouDaol = language.shouDaol[type];
        var songDaZhong = language.songDaZhong[type];
        var zhunBeiZhong = language.zhunBeiZhong[type];
        var zhunBeiFinsih = language.zhunBeiFinsih[type];
        var yiWanCheng = language.yiWanCheng[type];
        var yiQuXiao = language.yiQuXiao[type];
        for (var i = 0; i < result.data.data.length; i++) {
          if (
            result.data.data[i].orderType == 1 ||
            result.data.data[i].orderType == 101
          ) {
            result.data.data[i].orderType = 1;
          }
          if (
            result.data.data[i].orderType == 2 ||
            result.data.data[i].orderType == 102
          ) {
            result.data.data[i].orderType = 2;
          }
          if (
            result.data.data[i].orderType == 4 ||
            result.data.data[i].orderType == 5
          ) {
            result.data.data[i].orderType = 3;
          }
          if (
            result.data.data[i].orderStatusCode == "S001" ||
            result.data.data[i].orderStatusCode == "S002" ||
            result.data.data[i].orderStatusCode == "S003"
          ) {
            result.data.data[i].orderStatus = shouDaol;
            result.data.data[i].orderStatusBg = 1;
          } else if (result.data.data[i].orderStatusCode == "S004") {
            result.data.data[i].orderStatus = zhunBeiZhong;
            result.data.data[i].orderStatusBg = 2;
          } else if (
            result.data.data[i].orderStatusCode == "S005" ||
            result.data.data[i].orderStatusCode == "S009"
          ) {
            if (
              result.data.data[i].orderType == 1 ||
              result.data.data[i].orderType == 101
            ) {
              result.data.data[i].orderStatus = songDaZhong;
            } else {
              result.data.data[i].orderStatus = zhunBeiFinsih;
            }
            result.data.data[i].orderStatusBg = 3;
          } else if (
            result.data.data[i].orderStatusCode == "S010" ||
            result.data.data[i].orderStatusCode == "S006" ||
            result.data.data[i].orderStatusCode == "S007"
          ) {
            result.data.data[i].orderStatus = yiWanCheng;
            result.data.data[i].orderStatusBg = 4;
          } else if (result.data.data[i].orderStatusCode == "S008") {
            result.data.data[i].orderStatus = yiQuXiao;
          }
          var length = result.data.data[i].name.length;
          if (result.data.data[i].name.length > 1) {
            result.data.data[i].name.splice(length - 1, 1);
          }
          var nameList = result.data.data[i].name;
          for (var n = 0; n < nameList.length; n++) {
            if (nameList[n].indexOf("送遞費") > -1) {
              nameList.splice(n, 1);
              break;
            }
          }
        }
        for (var o = 0; o < result.data.data.length; o++) {
          var res = result.data.data[o];
          if (res.orderType == 1) {
            if (res.quoDateTime) {
              var time = res.quoDateTime.split("T");
              var D = time[0].split("-");
              var T = time[1].split(":");
              var NTY = new Date().getFullYear();
              var NTM = new Date().getMonth() + 1;
              var NTD = new Date().getDate();
              var NT = new Date(NTY + "/" + NTM + "/" + NTD).getTime();
              var YT = new Date(D[0] + "/" + D[1] + "/" + D[2]).getTime();
              if (YT != NT) {
                res.deliveryTime = D[1] + "/" + D[2] + " " + T[0] + ":" + T[1];
              } else {
                res.deliveryTime = T[0] + ":" + T[1];
              }
            }
          }
        }
        this.list = this.orderList = result.data.data;
        this.selectTab(this.type);
        this.$nextTick(() => {
          this.mescroll.endSuccess();
        });
      }
    },
    bodyScroll() {
      let scrollTop = document.querySelector("#oList").scrollTop;
      this.scrollTopHeight = scrollTop;
    },
  },
  computed: {
    lanType() {
      return this.$store.state.language;
    },
  },
  beforeRouteLeave(to) {
    if (to.name == "OrderDetail") {
      this.$store.commit("saveRouter", "OrderList");
    } else {
      this.$store.commit("clearRouter");
    }
  },
  activated() {
    if (this.scrollTopHeight) {
      document.querySelector("#oList").scrollTop = this.scrollTopHeight;
    }
  },
  mounted() {
    this.$nextTick(() => {
      var H = document.querySelector(".coupon").offsetHeight + 20;
      document.querySelector(".mescroll").style = `top:${H}px`;
    });
  },
  components: {
    OrderItem,
    NoneData,
    Topbar,
    MescrollVue,
  },
};
</script>
<style scoped>
.coupon {
  padding-bottom: 0.44rem;
}
.tabBar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 22px;
  color: #808080;
  height: 56px;
  position: fixed;
  top: 0.43rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  background-color: #fff;
}
.tabBar i {
  width: 1px;
  height: 16px;
  background-color: #808080;
}
.tabBar b {
  font-size: 0.12rem;
}
.tabBar p {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
}
.tabBar p span:last-child {
  width: 40%;
  height: 2px;
  position: absolute;
  bottom: 5px;
}
.tabBar p.active span:last-child {
  background-color: #e4022b;
}
.tabBar p.active {
  color: #e4022b;
  font-weight: 500;
}

.list {
  width: 100%;
  background-color: #f2f3f5;
  padding: 0.16rem;
  box-sizing: border-box;
  min-height: calc(100vh - 1.46rem);
  box-sizing: border-box;
}
.mescroll {
  position: fixed;
  top: 1rem;
  bottom: 0;
  height: auto;
  padding: 0 !important;
  margin: 0;
}
</style>